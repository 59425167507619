<template>
  <div>
    <v-card>
      <v-card-title>
        vendas anuais
      </v-card-title>
      <v-divider class="mt-0"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap mt-5 pb-0">
        <v-row class="mb-3">
          <v-col
            cols="12"
            md="3"
            class="pb-1"
          >
            <v-autocomplete
              v-model="region"
              :items="listRegionsItems"
              item-text="name"
              item-value="name"
              label="Origem"
              outlined
              dense
              hide-details
              cols="12"
              md="3"
              sm="4"
              lg="3"
              class="pb-1"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pb-1"
          >
            <v-autocomplete
              v-model="cpfVendor"
              :items="listVendors[0]"
              item-text="name"
              item-value="cpf"
              label="Vendedor"
              outlined
              dense
              hide-details
              cols="12"
              md="3"
              sm="4"
              lg="3"
              class="pb-1"
            />
          </v-col>
          <v-spacer></v-spacer>
          <div class="d-flex align-center pa-2">
            <v-btn
              class="mr-3"
              color="info"
              @click="getItemsFilter"
            >
              Filtrar
            </v-btn>
            <v-btn
              color="secondary"
              @click="clearFilter()"
            >
              Limpar
            </v-btn>
          </div>
        </v-row>
      </v-card-text>

      <v-simple-table
        v-if="dataTable.length > 0"
        fixed-header
        height="700px"
      >
        <thead>
          <tr>
            <th
              v-for="header in itemsHeadersTable"
              :key="header"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in dataTable"
            :key="item.id"
          >
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.sum01 }}
            </td>
            <td>
              {{ item.sum02 }}
            </td>
            <td>
              {{ item.sum03 }}
            </td>
            <td>
              {{ item.sum04 }}
            </td>
            <td>
              {{ item.sum05 }}
            </td>
            <td>
              {{ item.sum06 }}
            </td>
            <td>
              {{ item.sum07 }}
            </td>
            <td>
              {{ item.sum08 }}
            </td>
            <td>
              {{ item.sum09 }}
            </td>
            <td>
              {{ item.sum10 }}
            </td>
            <td>
              {{ item.sum11 }}
            </td>
            <td>
              {{ item.sum12 }}
            </td>
            <td>
              {{ item.media }}
            </td>
            <td>
              {{ item.quota }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-card-title
        v-else
        class="notData"
        color="warning"
      >
        Dados não localizados
      </v-card-title>
      <!-- <v-data-table
        :headers="headers"
        :items="dataTable"
      >
      </v-data-table> -->
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'

export default {
  data() {
    return {
      dataExist: false,
      loading: false,
      listRegions: [],
      listVendors: [],
      cpfVendor: '',
      region: '',
      headers: [
        { text: 'FUNCIONÁRIO', value: 'name', align: 'center' },
      ],
      dataTable: [],
      vendor: '',
      itemsHeadersTable: [],
    }
  },

  computed: {
    listRegionsItems() {
      const list = []

      this.listRegions.map(items => {
        items.map(item => {
          list.push(item.region)
        })
      })

      return list
    },
  },

  created() {
    this.getItems()
    this.getItemsListVendors()
    this.getItemsListRegions()
  },
  methods: {
    async getItemsListRegions() {
      await axiosIns.get('api/v1/records/region/index')
        .then(res => {
          this.listRegions.push(res.data.data)
        })
    },
    async getItemsListVendors() {
      await axiosIns.get('api/v1/records/user/index')
        .then(res => {
          this.listVendors.push(res.data.data)
        })
    },

    // Só faz um primeiro filtro ao carregar a página
    async getItems() {
      this.loading = true

      const body = {
        company: 'IMPERATRIZ',
      }
      await axiosIns.post('api/v1/integration/contract/annual_sales_reports', body)
        .then(res => {
          this.itemsHeadersTable = {
            name: 'NOME',
            mes01: res.data.data[0].mes01,
            mes02: res.data.data[0].mes02,
            mes03: res.data.data[0].mes03,
            mes04: res.data.data[0].mes04,
            mes05: res.data.data[0].mes05,
            mes06: res.data.data[0].mes06,
            mes07: res.data.data[0].mes07,
            mes08: res.data.data[0].mes08,
            mes09: res.data.data[0].mes09,
            mes10: res.data.data[0].mes10,
            mes11: res.data.data[0].mes11,
            mes12: res.data.data[0].mes12,
            quota: 'MEDIA',
            average: 'TOTAL',
          }

          this.dataTable = res.data.data
          this.loading = false
        })
    },

    // Só faz um primeiro filtro ao carregar a página

    async getItemsFilter() {
      this.loading = true

      const body = {
        company: this.region,
        vendor_cpf: this.cpfVendor,
      }
      await axiosIns.post('api/v1/integration/contract/annual_sales_reports', body)
        .then(res => {
          this.dataTable = res.data.data
          this.itemsHeadersTable = {
            name: 'NOME',
            mes01: res.data.data[0].mes01,
            mes02: res.data.data[0].mes02,
            mes03: res.data.data[0].mes03,
            mes04: res.data.data[0].mes04,
            mes05: res.data.data[0].mes05,
            mes06: res.data.data[0].mes06,
            mes07: res.data.data[0].mes07,
            mes08: res.data.data[0].mes08,
            mes09: res.data.data[0].mes09,
            mes10: res.data.data[0].mes10,
            mes11: res.data.data[0].mes11,
            mes12: res.data.data[0].mes12,
            quota: 'MEDIA',
            average: 'TOTAL',
          }
        })
      this.loading = false
    },

    clearFilter() {
      this.getItems()
      this.cpfVendor = ''
      this.region = ''
    },
  },
}
</script>
<style>
  .notData{
    color: #ff9800 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
